<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :vid="vid"
    :name="$attrs.label"
    :rules="rules"
    :mode="validationMode"
  >
    <b-field
      :expanded="expanded"
      v-bind="$attrs"
      :disabled="disabled"
      :type="{
        'is-default': disabled,
        'is-danger': errors[0],
        'is-empty':
          !innerValue || (innerValue && innerValue.length == 0) || errors[0]
      }"
      :class="{ 'is-empty': innerValue && innerValue.length == 0 && errors[0] }"
      :message="hideDetails ? '' : errors"
    >
      <b-select
        v-model="innerValue"
        :placeholder="placeholder"
        :size="size"
        :disabled="disabled"
        :expanded="selectExpanded"
        v-bind="$attrs"
        @input="val => $emit('input', val)"
      >
        <option
          :hidden="placeholderOptionHidden"
          :disabled="placeholderOptionDisabled"
          value
        >
          {{ placeholder || $t("choose") }}
        </option>
        <slot />
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null,
      default: ""
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    },
    selectExpanded: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    validationMode: {
      type: String,
      default: "eager"
    }
  },
  data: () => ({
    innerValue: ""
  }),
  computed: {
    placeholderOptionHidden() {
      if (this.innerValue == null || this.innerValue == "") return false;
      return (
        (this.rules.indexOf("required") != -1 || this.required) &&
        ((this.innerValue != null && this.innerValue != "") ||
          (this.innerValue && this.innerValue.length > 0))
      );
    },
    placeholderOptionDisabled() {
      return this.rules.indexOf("required") != -1 || this.required;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("change", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
