<template>
  <!--
  is-level-${level}: is used for z-index to show childs in top
  has-child-1: is used to transform the component by 200px to left (to still visible)
  has-child-2: same as has-child-1 to display both children and parent
  -->
  <b-sidebar
    :overlay="level == 'main'"
    right
    fullheight
    open
    position="static"
    class="drawer"
    :class="`is-${width} is-level-${level}${hasChild1 ? ' has-child-1' : ''}${
      hasChild2 ? ' has-child-2' : ''
    } ${background ? 'visible-background' : ''}`"
  >
    <div class="close" @click="close(level)">
      <b-icon icon="close" size="is-medium" />
    </div>
    <div class="drawer-content">
      <slot />
    </div>
  </b-sidebar>
</template>

<script>
import closeMixin from "@/plugins/escape-close.js";

export default {
  mixins: [closeMixin],
  props: {
    width: {
      type: [String, Number],
      required: true,
    },
    background: {
      type: Boolean,
      default: false,
    },
    level: {
      type: [String, Number],
      default: "main",
    },
    hasChild1: {
      type: Boolean,
      default: false,
    },
    hasChild2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mounted: false,
      escListenet: null,
    };
  },
  mounted() {
    this.mounted = true;
  },
  destroyed() {
    document.removeEventListener("keyup", this.escListenet);
  },
  methods: {
    close() {
      if (!this.mounted || this.hasChild1) return;
      // this.$bus.$emit("close-drawer");
      this.closeDrawer(this.level);
    },
  },
};
</script>
