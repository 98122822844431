import Vue from "vue";
import Router from "vue-router";
import Cookie from "js-cookie";

Vue.use(Router);

const routes = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // { path: '/', redirect: { path: 'dashboard' } },
    {
      path: "/signin",
      name: "Auth",
      component: () => import("@/pages/Signin")
    },
    {
      path: "/password/reset/:reset_token/:email_token",
      name: "reset-password",
      component: () => import("@/pages/ResetPassword")
    },
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/pages/Index")
    },
    {
      path: "/users/list",
      name: "users-list",
      component: () => import("@/pages/users/List")
    },
    {
      path: "/users/mailing",
      name: "users-crm",
      component: () => import("@/pages/users/Crm")
    },
    {
      name: "user-dashboard",
      path: "/users/:user_id",
      component: () => import("@/pages/users/Profile")
    },
    {
      name: "ecommerce-orders",
      path: "/ecommerce/orders",
      component: () => import("@/pages/ecommerce/Orders")
    },
    {
      name: "ecommerce-deals",
      path: "/ecommerce/deals",
      component: () => import("@/pages/ecommerce/Deals")
    },
    {
      name: "ecommerce-slides",
      path: "/ecommerce/slides",
      component: () => import("@/pages/ecommerce/Slides")
    },
    {
      name: "ecommerce-vouchers",
      path: "/ecommerce/vouchers",
      component: () => import("@/pages/ecommerce/Vouchers")
    },
    {
      name: "ecommerce-autopricing",
      path: "/ecommerce/autopricing",
      component: () => import("@/pages/ecommerce/Autopricing")
    },
    {
      name: "ecommerce-cashback",
      path: "/ecommerce/cashback",
      component: () => import("@/pages/ecommerce/Cashback")
    },
    {
      name: "analytics-overview",
      path: "/analytics/overview",
      component: () => import("@/pages/analytics/Overview")
    },
    {
      name: "analytics-ranking",
      path: "/analytics/ranking",
      component: () => import("@/pages/analytics/Ranking")
    },
    {
      name: "analytics-graphs",
      path: "/analytics/graphs",
      component: () => import("@/pages/analytics/Graphs")
    },
    {
      name: "catalog-products",
      path: "/catalog/products",
      component: () => import("@/pages/catalog/Products")
    },
    {
      name: "create-product",
      path: "/catalog/products/create",
      component: () => import("@/pages/catalog/Product")
    },
    {
      name: "edit-product",
      path: "/catalog/products/:sku(\\d+)/:action?",
      component: () => import("@/pages/catalog/Product")
    },
    {
      name: "catalog-packs",
      path: "/catalog/packs",
      component: () => import("@/pages/catalog/Packs")
    },
    {
      name: "create-pack",
      path: "/catalog/packs/create",
      component: () => import("@/pages/catalog/Pack")
    },
    {
      name: "edit-pack",
      path: "/catalog/packs/:id(\\d+)",
      component: () => import("@/pages/catalog/Pack")
    },
    {
      name: "catalog-categories",
      path: "/catalog/categories",
      component: () => import("@/pages/catalog/categories/Index")
    },
    {
      name: "catalog-categories-create",
      path: "/catalog/categories/create",
      component: () => import("@/pages/catalog/categories/Create")
    },
    {
      name: "catalog-categories-update",
      path: "/catalog/categories/:category_id",
      component: () => import("@/pages/catalog/categories/Create")
    },
    {
      name: "catalog-brands",
      path: "/catalog/brands",
      component: () => import("@/pages/catalog/brands/Index")
    },
    {
      name: "catalog-brands-create",
      path: "/catalog/brands/create",
      component: () => import("@/pages/catalog/brands/Create")
    },
    {
      name: "catalog-brands-update",
      path: "/catalog/brands/:brand_id",
      component: () => import("@/pages/catalog/brands/Create")
    },
    {
      name: "nutrifacts",
      path: "/catalog/nutrifacts",
      component: () => import("@/pages/catalog/NutriFacts")
    },
    {
      name: "attributes",
      path: "/catalog/attributes",
      component: () => import("@/pages/catalog/Attributes")
    },
    {
      name: "reviews",
      path: "/catalog/reviews",
      component: () => import("@/pages/catalog/reviews/Index")
    },
    {
      name: "purchasing-suppliers",
      path: "/purchasing/suppliers",
      component: () => import("@/pages/purchasing/Suppliers")
    },
    {
      name: "purchasing-inventory",
      path: "/purchasing/inventory",
      component: () => import("@/pages/purchasing/Inventory")
    },
    {
      name: "purchasing-carriers",
      path: "/purchasing/carriers",
      component: () => import("@/pages/purchasing/Carriers")
    },
    {
      name: "purchasing-carriers-create",
      path: "/purchasing/carriers/create",
      component: () => import("@/pages/purchasing/Carrier")
    },
    {
      name: "purchasing-carriers-update",
      path: "/purchasing/carriers/:carrier_id",
      component: () => import("@/pages/purchasing/Carrier")
    },
    {
      name: "purchasing-warehouses",
      path: "/purchasing/warehouses",
      component: () => import("@/pages/purchasing/Warehouses")
    },
    {
      name: "international-countries",
      path: "/international/countries",
      component: () => import("@/pages/international/Countries")
    },
    {
      name: "international-translations",
      path: "/international/translations",
      component: () => import("@/pages/international/Translations")
    },
    {
      name: "blog",
      path: "/blog/articles",
      component: () => import("@/pages/blog/articles/Index")
    },
    {
      name: "blog-create",
      path: "/blog/articles/create",
      component: () => import("@/pages/blog/articles/Create")
    },
    {
      name: "blog-update",
      path: "/blog/articles/:blog_id",
      component: () => import("@/pages/blog/articles/Create")
    },
    {
      name: "blog-categories",
      path: "/blog/categories/",
      component: () => import("@/pages/blog/categories/Index")
    },
    {
      name: "blog-categories-create",
      path: "/blog/categories/create",
      component: () => import("@/pages/blog/categories/Create")
    },
    {
      name: "blog-categories-update",
      path: "/blog/categories/:category_id",
      component: () => import("@/pages/blog/categories/Create")
    },
    {
      name: "activities",
      path: "/settings/activities",
      component: () => import("@/pages/settings/Activity")
    },
    {
      name: "pages",
      path: "/settings/pages",
      component: () => import("@/pages/settings/pages/Index")
    },
    {
      name: "page-translations",
      path: "/settings/pages/:page_id",
      component: () => import("@/pages/settings/pages/Translations")
    },
    {
      name: "variables",
      path: "/settings/variables",
      component: () => import("@/pages/settings/configVars/Index")
    },
    {
      name: "settings-agents",
      path: "/settings/agents",
      component: () => import("@/pages/settings/agents/Index")
    },
    {
      name: "settings-agent-profile",
      path: "/settings/agents/:agent_id",
      component: () => import("@/pages/settings/agents/Profile")
    },
    {
      name: "settings-roles",
      path: "/settings/roles",
      component: () => import("@/pages/settings/Roles")
    },
    {
      name: "settings-cache",
      path: "/settings/cache",
      component: () => import("@/pages/settings/Cache")
    },
    {
      name: "settings-commands",
      path: "/settings/commands",
      component: () => import("@/pages/settings/Commands")
    },
    {
      name: "settings-tags",
      path: "/settings/tags",
      component: () => import("@/pages/settings/Tags")
    },
    {
      name: "cs-tickets",
      path: "/cs/tickets",
      component: () => import("@/pages/cs/Tickets")
    },
    {
      name: "cs-ticket",
      path: "/cs/tickets/:ticket_id",
      component: () => import("@/pages/cs/Tickets")
    },
    {
      name: "not-found",
      path: "/*",
      component: () => import("@/pages/404")
    }
  ]
});

routes.beforeEach((to, from, next) => {
  if (to.path != "/signin" && !to.path.includes("/password/reset/")) {
    if (Cookie.get("token")) {
      next();
    } else {
      window.location.href = "/signin?to=" + to.fullPath;
    }
  } else {
    if (Cookie.get("token")) {
      next("/");
    } else {
      next();
    }
  }
});

export default routes;
