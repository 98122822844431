export default {
  address: {
    city: "ex: Paris",
    line1: "ex: 25 avenue de New York",
    line2: "ex: Appartement 23",
    zip: "ex: 75001"
  },
  attribute: {
    colors: "ex: Bleu",
    flavors: "ex: Chocolat",
    materials: "ex: Bois"
  },
  blog: {
    article: "ex: Objectifs visés : développement de la masse musculaire et de la force sur les exercices de musculation par la consommation d’une alimentation saine ou sans source de protéines animales...",
    meta_description: "ex: Découvrez dans cet article, un exemple de programme prise de masse pour un régime vegan. Tout vous est expliqué.",
    meta_title: "ex: Retrouvez notre programme prise de masse pour un régime vegan",
    nutrifacts: "ex: 25g",
    permalink: "ex: programme-prise-de-masse-regime-vegan",
    permalink_redirect: "ex: store/whey-isolate",
    time: "ex: 6min",
    title: "ex: Programme prise de masse régime vegan",

    category: {
      description: "ex: ",
      description_short: "ex: ",
      h1: "ex: ",
      meta_description: "ex: ",
      meta_title: "ex: ",
      name: "ex: ",
      permalink: "ex: "
    }
  },
  brand: {
    description:
      "ex: La marque My Muscle revient avec une nouvelle philosophie et des nouvelles formules...",
    description_short: "ex: La marque My Muscle, la meilleure de nutrition...",
    h1: "ex: MyMuscle, le meilleur de la nutrition",
    meta_description:
      "ex: MyMuscle, la meilleure marque de nutrition, livraison en 24h ...",
    meta_title: "ex: MyMuscle - Achat / Vente",
    name: "ex: MyMuscle",
    permalink: "ex: mymuscle"
  },
  carrier: {
    automatic: " Automatic",
    name: "ex: DPD",
    product: "ex: Chrono Classic",
    rank: "ex: 1",
    code: "ex: CHR_C",
    code_mp: "ex: Chronopost, TRK",
    memo: "ex: For France",
    time: "ex: 15:30",
    tracking_link: "ex: http://www.chronopost.fr/expedier/inputLTNumbersNoJahia.do?listeNumeros={tracking_num}",
  },
  category: {
    description:
      "ex: La whey protéine isolate est un type de whey protéine qui possède un taux de protéines supérieur à 80%...",
    description_short:
      "ex: La whey protéine isolate est un type de whey protéine...",
    h1: "ex: Whey isolate",
    meta_description:
      "ex: Achetez vos {category} sur {site}. Livraison {shipping_method} offerte dès {free_shipping_from}. Paiement 100% sécurisé...",
    meta_title: "ex: Whey isolate - Achat / Vente",
    name: "ex: Whey isolate",
    permalink: "ex: whey-isolate"
  },
  company: {
    name: "ex: Nutriweb",
    vat_num: "ex: 1123456789",
  },
  config_values: {
    description: "ex: describe what the variable is used for",
    value: "ex: 24",
    variable: "ex: my_variable",
  },
  country: {
    iso: "ex: FR",
    code: "ex: 33",
    continent: "ex: EU",
    vat_display: "ex: FR {num}",
    label: "ex: France",
    language: "ex: Français"
  },
  date: {
    day: "ex: 26/11/2020",
    day2: "ex: 2020/11/26",
    day3: "ex: 2020-11-26",
    month: "ex: 12/2020",
    time: "ex: 26/11/2020 12:26"
  },
  inventory: {
    name: "ex: MyMuscle-Ue343"
  },
  number: {
    "1": "ex: 6",
    "2": "ex: 24",
    "3": "ex: 225",
    "4": "ex: 1465",
    "6": "ex: 625437",
    decimal_1: "ex: 12,5",
    decimal_2: "ex: 12,55",
    discount: "ex: 10,5"
  },
  nutrifacts: {
    allergen: "ex: Contient du lait",
    amino: "ex: Arginine",
    direction: "ex: Mélanger une dose de 30g de poudre dans 150 ml d'eau...",
    ingredient: "ex: Isolat de protéines de lactosérum (lait) (80%)",
    macro: "ex: Glucides",
    micro: "ex: Vitamine E",
    nrv: "ex: 1.60",
    name: "ex: Spray de cuisson à la noix de coco",
    warning: "Ne se substitue pas à un régime alimentaire équilibré et varié..."
  },
  order: {
    note: "ex: BDC N4BRANDS 6456987",
    incoterm: "ex: EXW",
    tracking_num: "ex: XU185150066FR" ,
    mp_id: "8D61A57C5A670002a6152039cee5ccd86ba981c2dbabed0",
  },
  pack: {
    description:
      "ex: Iso whey zero est une protéine en poudre de type whey isolate fabriquée par la marque...",
    directions: "ex: Mélanger une dose de 30g de poudre dans 150 ml d'eau...",
    meta_description:
      "ex: composez votre pack de meilleures produits de musculation...",
    meta_title: "ex: Meilleure pack Musculation",
    name: "ex: Pack Musculation",
    permalink: "ex: pack-musculation",
  },
  page: {
    prefix: "ex: store",
    route: "ex: brands/:param",
    url: "ex: ",
    meta_title: "ex: ",
    meta_description: "ex: ",
    h1: "ex: ",
    content: "ex: "
  },
  permission: {
    display: "ex: Orders List",
    name: "ex: orders",
    description:"ex: View, create & update orders"
  },
  price: {
    decimal: "ex: 54.80",
    int: "ex: 25"
  },
  product: {
    barcode: "ex: 5060469983653",
    description:
      "ex: Iso whey zero est une protéine en poudre de type whey isolate fabriquée par la marque...",
    directions: "ex: Mélanger une dose de 30g de poudre dans 150 ml d'eau...",
    flavor: "ex: --chocolat",
    keypoints: "ex: Sans gluten, sans lactose, ...",
    meta_description:
      "ex: Iso whey zero est la whey native la plus vendue en Europe...",
    meta_title: "ex: Meilleure iso whey du marché",
    name: "ex: Iso whey zero",
    permalink: "ex: iso-whey-zero",
    sku: "ex: Iso whey -- choco",
    hs_code: "ex: 2106.9092.40"
  },
  review: {
    reply: "ex: ",
    review: "ex: "
  },
  role: {
    display: "ex: Agent Manager",
    name: "ex: agent.manager"
  },
  slide: {
    button: "ex: Acheter maintenant",
    description:
      "ex: Profitez d'une réduction de 20% sur tous les produits mymuscle",
    title: "ex: Offre spéciale Mymuscle",
    url: "ex: /brand/mymuscle"
  },
  supplier: {
    name: "ex: NASKOR"
  },
  user: {
    country_code: "ex: +33",
    deleteReason: "ex: Le client n'est plus intéressé",
    email: "ex: j.smith@mail.com",
    fname: "ex: John",
    lname: "ex: Smith",
    password: "••••••••••",
    phone: {
      code: "ex: +33",
      country: "ex: France",
      number: "ex: 06 11 22 33 44"
    },
    signature: "ex: John S."
  },
  voucher: {
    amount: "ex: 25",
    code: "ex: BLACKFRIDAY25",
    min_amount: "ex: 100"
  }
};
