<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :vid="vid"
    :name="$attrs.name || label"
    :rules="rules"
    :mode="validationMode"
  >
    <b-field
      v-bind="$attrs"
      :label="label"
      :disabled="disabled"
      :type="{
        'is-default': disabled,
        'is-danger': errors[0],
      }"
      :message="hideDetails ? null : [...errors, ...message]"
    >
      <!-- <b-input v-model="innerValue" :placeholder="placeholder" v-bind="$attrs"></b-input> -->
      <b-datepicker
        v-if="!timePicker"
        v-model="innerValue"
        v-bind="$attrs"
        :placeholder="placeholder"
        :editable="editable"
        :disabled="disabled"
        :range="range"
        :multiple="multiple"
        :min-date="minDate ? new Date(minDate) : null"
        :max-date="maxDate ? new Date(maxDate) : null"
      >
        <!-- <b-button
          class="is-danger"
          @click.prevent="innerValue = null"
        >
          <b-icon icon="close" />
          <span>{{ $t("delete") }}</span>
        </b-button> -->
      </b-datepicker>
      <b-datetimepicker
        v-else
        v-model="innerValue"
        v-bind="$attrs"
        :placeholder="placeholder"
        :editable="editable"
        :disabled="disabled"
        :range="range"
        :multiple="multiple"
        :min-datetime="minDate ? new Date(minDate) : null"
        :max-datetime="maxDate ? new Date(maxDate) : null"
        :timepicker="{'increment-minutes': incrementMinutes, 'hour-format': '24'}"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import moment from "moment";
export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: ""
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null,
      default: ""
    },
    placeholder: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    range: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    },
    minDate: {
      type: [String, Date],
      default: "01/01/2013"
    },
    maxDate: {
      type: [String, Date],
      default: null
    },
    format: {
      type: String,
      default: "YYYY-MM-DD"
    },
    timePicker: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    validationMode: {
      type: String,
      default: "eager"
    },
    incrementMinutes: {
      type: Number,
      default: 1
    },
  },
  data: () => ({
    innerValue: null
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal ? moment(newVal).format(this.format) : "");
    },
    // Handles external model changes.
    value(newVal) {
      if (typeof newVal == "string" && newVal.length) {
        this.innerValue = new Date(newVal);
      }
    }
  },
  created() {
    if (this.value) {
      this.innerValue = new Date(this.value);
    }
  }
};
</script>
