<template>
  <div v-if="isAuth" class="layout">
    <b-sidebar
      class="layout-sidebar"
      position="static"
      :mobile="mobile"
      :expand-on-hover="expandOnHover"
      :reduce="reduce"
      open
    >
      <div
        class="logo is-flex is-justify-content-space-between is-align-items-center"
      >
        <img
          v-if="reduce"
          src="/icon.png"
          alt="Nutriweb"
          width="40"
          height="40"
        />
        <img
          v-else
          src="/img/logo.svg"
          alt="Nutriweb"
          width="134"
          height="46"
        />
        <span v-if="$device.mobile" @click="changeSideBar">
          <b-icon icon="close" type="is-white" />
        </span>
      </div>
      <b-menu class="is-custom-mobile mb-4">
        <template v-for="item in items">
          <b-menu-list
            v-if="
              item.children &&
              (item.hasOwnProperty('condition') ? item.condition : true)
            "
            :key="item.title"
          >
            <b-menu-item
              :label="item.title"
              :active="$route.path.includes(item.group)"
              :expanded="$route.path.includes(item.group)"
              :icon="item.icon"
            >
              <template slot="label" slot-scope="props">
                {{ item.title }}
                <b-icon
                  :icon="props.expanded ? 'chevron-down' : 'chevron-up'"
                  class="is-pulled-right"
                />
              </template>
              <template v-for="child in item.children">
                <b-menu-item
                  v-if="
                    child.hasOwnProperty('condition') ? child.condition : true
                  "
                  :key="child.title"
                  tag="router-link"
                  :active="$route.path.includes(`${item.group}/${child.to}`)"
                  :icon="child.icon"
                  :to="`${item.group}/${child.to}`"
                >
                  <span slot="label">
                    {{ child.title }}

                    <b-tag
                      v-if="child.tag"
                      type="is-dark"
                      size="is-small"
                      class="c-float-right"
                      >{{ child.tag }}</b-tag
                    >
                  </span>
                </b-menu-item>
              </template>
            </b-menu-item>
          </b-menu-list>
          <b-menu-list
            v-else-if="item.hasOwnProperty('condition') ? item.condition : true"
            :key="item.title"
          >
            <b-menu-item
              :key="item.title"
              tag="router-link"
              :to="item.to"
              :active="$route.path == item.to"
              :label="item.title"
              :icon="item.icon"
            />
          </b-menu-list>
        </template>
      </b-menu>
    </b-sidebar>

    <main class="main">
      <b-navbar :mobile-burger="false" class="px">
        <template slot="brand">
          <span @click="changeSideBar">
            <b-icon icon="text" size="is-medium" />
          </span>
        </template>
        <template slot="end">
          <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
            <div slot="trigger" class="navbar-item pr-0">
              <div class="mr-2">
                <span class="text-dark"
                  ><b>{{ $signature }}</b></span
                >
                <span
                  class="is-block has-text-grey-light is-size-8"
                  style="line-height: 1"
                  >{{ role }}</span
                >
              </div>
              <img src="/img/avatars/blank.svg" />
            </div>

            <b-dropdown-item
              aria-role="listitem"
              @click="
                openDrawer('resetAgentPassword', {
                  level: 'main',
                  width: 'small',
                })
              "
            >
              <b-icon icon="lock" size="is-small" />
              {{ $t("change_password") }}
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="signout">
              <b-icon icon="logout" type="is-danger" size="is-small" />
              {{ $t("signout") }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-navbar>

      <Drawer
        v-if="rightDrawer.show"
        :width="rightDrawer.width"
        :has-child1="transformparent1"
        :has-child2="transformparent2"
        :background="rightDrawer.background"
        level="main"
      >
        <component
          :is="rightDrawer.content"
          :key="rightDrawer.options.key"
          :options="rightDrawer.options"
        />
      </Drawer>
      <!-- Drawer level 1 -->
      <Drawer
        v-if="rightDrawerChild1.show"
        :width="rightDrawerChild1.width"
        :has-child1="transformChild1"
        :level="1"
      >
        <component
          :is="rightDrawerChild1.content"
          :options="rightDrawerChild1.options"
        />
      </Drawer>
      <!-- Drawer level 2 -->
      <Drawer
        v-if="rightDrawerChild2.show"
        :width="rightDrawerChild2.width"
        :level="2"
      >
        <component
          :is="rightDrawerChild2.content"
          :options="rightDrawerChild2.options"
        />
      </Drawer>

      <b-modal v-model="showModal" :width="420">
        <Card>
          <div slot="header">
            <div class="is-size-5 px py-4">
              <b>{{ $t("update_shipping_question") }}</b>
            </div>
            <hr class="my-0" />
          </div>
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="div"
          >
            <p class="mb-4">
              <b>{{ $t("old_value") }}:</b> {{ summary.shipping.formatted }}
            </p>
            <form
              method="post"
              autocomplete="off"
              @submit.prevent="handleSubmit(updateShipping)"
            >
              <b-input-validation
                v-model="summary.shipping.new"
                class="is-flex mb-1"
                vid="new_shipping"
                :label="$t('new_value')"
                rules="required"
                :placeholder="placeholders.number.discount"
                :suffix="summary.currency.symbol"
              />

              <b-button
                class="mr-4"
                type="is-success"
                :loading="loadingShipping"
                :disabled="loadingShipping"
                tag="input"
                native-type="submit"
                :value="$t('save')"
              >
                {{ $t("save") }}
              </b-button>
              <b-button :disabled="loadingShipping" @click="showModal = false">
                {{ $t("cancel") }}
              </b-button>
            </form>
          </ValidationObserver>
        </Card>
      </b-modal>
      <router-view />
    </main>
  </div>
  <router-view v-else />
</template>

<script>
import Cookie from "js-cookie";
import jwt from "jsonwebtoken";
import { mapGetters } from "vuex";

import Drawer from "@/components/Drawer";

export default {
  name: "DefaultLayout",
  components: {
    Drawer,
  },
  data() {
    return {
      showModal: false,
      loadingShipping: false,
      summary: null,
      orderId: null,
      expandOnHover: false,
      mobile: "hide",
      reduce: true,
      transformChild1: false,
      transformparent2: false,
      transformparent1: false,
      menus: [
        {
          icon: "lock",
          text: "change pass",
        },
        {
          icon: "logout-variant",
          text: "log out",
        },
      ],
      items: [
        {
          icon: "view-dashboard",
          title: this.$i18n.t("dashboard"),
          to: "/",
        },
        // {
        //   icon: "account-multiple",
        //   title: this.$i18n.tc("customer", 2),
        //   to: "/users"
        // },
        {
          group: "/users",
          icon: "account-multiple",
          title: this.$i18n.tc("customer", 2),
          condition:
            this.$can("read", "users") || this.$can("read", "users.crm"),
          children: [
            {
              title: this.$i18n.t("list"),
              icon: "format-list-bulleted",
              to: "list",
              condition: this.$can("read", "users"),
            },
            {
              title: this.$i18n.t("mailing"),
              icon: "email",
              to: "mailing",
              condition: this.$can("read", "users.crm"),
            },
          ],
        },
        {
          group: "/ecommerce",
          icon: "cart",
          title: this.$i18n.t("ecommerce"),
          condition:
            this.$can("read", "orders") ||
            this.$can("read", "catalog.deals") ||
            this.$can("read", "users.cashback") ||
            this.$can("read", "orders.vouchers"),
          children: [
            {
              title: this.$i18n.tc("order", 2),
              icon: "cart",
              to: "orders",
              condition: this.$can("read", "orders"),
            },
            {
              title: this.$i18n.tc("deal", 2),
              icon: "sale",
              to: "deals",
              condition: this.$can("read", "catalog.deals"),
            },
            {
              title: this.$i18n.tc("slide", 2),
              icon: "animation-play",
              to: "slides",
              condition: this.$can("read", "catalog.deals"),
            },
            {
              title: this.$i18n.tc("voucher", 2),
              icon: "ticket-percent",
              to: "vouchers",
              condition: this.$can("read", "orders.vouchers"),
            },
            {
              title: this.$i18n.t("cashback"),
              icon: "currency-usd",
              to: "cashback",
              condition: this.$can("read", "users.cashback"),
            },
            {
              title: this.$i18n.t("autopricing"),
              icon: "cash-register",
              to: "autopricing",
              condition: this.$can("read", "catalog.autopricing"),
            },
          ],
        },
        {
          group: "/cs",
          icon: "face-agent",
          title: this.$i18n.t("customer_service"),
          condition: this.$can("read", "tickets"),
          children: [
            {
              title: this.$i18n.tc("ticket", 2),
              icon: "ticket-outline",
              to: "tickets",
              condition: this.$can("read", "tickets"),
            },
          ],
        },
        {
          group: "/analytics",
          icon: "chart-areaspline",
          title: this.$tc("analytic", 2),
          condition: this.$can("read", "orders.analytics"),
          children: [
            {
              title: this.$t("overview"),
              icon: "view-day",
              to: "overview",
              condition: this.$can("read", "orders.analytics"),
            },
            {
              title: this.$i18n.t("ranking"),
              icon: "format-list-numbered",
              to: "ranking",
              condition: this.$can("read", "orders.analytics"),
            },
            {
              title: this.$tc("graph", 2),
              icon: "chart-bell-curve-cumulative",
              to: "graphs",
              condition: this.$can("read", "orders.analytics"),
            },
          ],
        },
        {
          group: "/catalog",
          icon: "package-variant",
          title: this.$i18n.t("catalog"),
          condition:
            this.$can("read", "catalog") ||
            this.$can("read", "catalog.nutrifacts") ||
            this.$can("read", "catalog.reviews"),
          children: [
            {
              title: this.$i18n.tc("product", 2),
              icon: "file-powerpoint-box",
              to: "products",
              condition: this.$can("read", "catalog"),
            },
            {
              title: this.$i18n.tc("pack", 2),
              icon: "package-variant",
              to: "packs",
              condition: this.$can("read", "catalog"),
            },
            {
              title: this.$i18n.tc("category", 2),
              icon: "shape",
              to: "categories",
              condition: this.$can("read", "catalog"),
            },
            {
              title: this.$i18n.tc("brand", 2),
              icon: "watermark",
              to: "brands",
              condition: this.$can("read", "catalog.brands"),
            },
            {
              title: this.$i18n.t("nutritional_values"),
              icon: "barcode",
              to: "nutrifacts",
              condition: this.$can("read", "catalog.nutrifacts"),
            },
            {
              title: this.$i18n.t("reviews"),
              icon: "star-cog",
              to: "reviews",
              condition: this.$can("read", "catalog.reviews"),
            },
            {
              title: this.$i18n.tc("attribute", 2),
              icon: "variable",
              to: "attributes",
              condition: this.$can("read", "catalog"),
            },
          ],
        },
        {
          group: "/purchasing",
          icon: "dolly",
          title: this.$i18n.t("logistic"),
          condition:
            this.$can("read", "purchasing.suppliers") ||
            this.$can("read", "purchasing.inventory"),
          children: [
            {
              title: this.$i18n.tc("supplier", 2),
              icon: "domain",
              to: "suppliers",
              condition: this.$can("read", "purchasing.suppliers"),
            },
            {
              title: this.$i18n.tc("inventory", 2),
              icon: "store",
              to: "inventory",
              condition: this.$can("read", "purchasing.inventory"),
            },
            {
              title: this.$i18n.tc("carrier", 2),
              icon: "truck",
              to: "carriers",
              condition: this.$can("read", "purchasing.carriers"),
            },
            {
              title: this.$i18n.tc("warehouse", 2),
              icon: "warehouse",
              to: "warehouses",
              // condition: this.$can("read", ""),
              tag: this.$i18n.t("coming_soon"),
            },
          ],
        },
        {
          group: "/international",
          icon: "web",
          title: this.$i18n.t("international"),
          condition:
            this.$can("read", "international.countries") ||
            this.$can("read", "international.translations"),
          children: [
            {
              title: this.$i18n.tc("country", 2),
              icon: "earth",
              to: "countries",
              condition: this.$can("read", "international.countries"),
            },
            {
              title: this.$i18n.tc("translation", 2),
              icon: "translate",
              to: "translations",
              condition: this.$can("read", "international.translations"),
              tag: this.$i18n.t("coming_soon"),
            },
          ],
        },
        {
          group: "/blog",
          icon: "post",
          title: this.$i18n.t("blog"),
          condition: this.$can("read", "blog"),
          children: [
            {
              title: this.$i18n.tc("article", 2),
              icon: "text-box",
              to: "articles",
              condition: this.$can("read", "blog"),
            },
            {
              title: this.$i18n.tc("category", 1),
              icon: "shape",
              to: "categories",
            },
          ],
        },
        {
          group: "/settings",
          icon: "cog-outline",
          title: this.$i18n.tc("setting", 2),
          condition:
            this.$can("read", "settings.activities") ||
            this.$can("read", "settings.agents") ||
            this.$can("read", "settings.permissions") ||
            this.$can("read", "settings.cache") ||
            this.$can("read", "settings.pages"),
          children: [
            {
              title: this.$i18n.tc("page", 2),
              icon: "monitor",
              to: "pages",
              condition: this.$can("read", "settings.pages"),
            },
            {
              title: this.$i18n.tc("variable", 2),
              icon: "variable-box",
              to: "variables",
              condition: true,
              tag: this.$i18n.t("new"),
            },
            {
              title: this.$i18n.tc("activity", 2),
              icon: "timeline-text",
              to: "activities",
              condition: this.$can("read", "settings.activities"),
            },
            {
              title: this.$i18n.tc("agent", 2),
              icon: "account-group",
              to: "agents",
              condition: this.$can("read", "settings.agents"),
            },
            {
              title:
                this.$i18n.tc("role", 2) +
                " & " +
                this.$i18n.tc("permission", 2),
              icon: "shield-account",
              to: "roles",
              condition: this.$can("read", "settings.permissions"),
            },
            {
              title: this.$i18n.t("cache"),
              icon: "database-refresh",
              to: "cache",
              condition: this.$can("read", "settings.cache"),
            },
            {
              title: this.$i18n.tc("tags", 2),
              icon: "tag-outline",
              to: "tags",
              condition: this.$can("read", "settings.tags"),
            },
            {
              title: this.$i18n.t("commands"),
              icon: "language-php",
              to: "commands",
              condition: this.$can("read", "settings.cli"),
            },
          ],
        },
      ],
    };
  },
  computed: {
    isAuth() {
      return Cookie.get("token") ? true : false;
    },
    role() {
      if (this.isAuth) {
        let token = Cookie.get("token");
        let decoded = jwt.decode(token);
        return decoded.role && decoded.role.value;
      }
      return "";
    },
    ...mapGetters({
      rightDrawer: "getRightDrawer",
      rightDrawerChild1: "getRightDrawerChild1",
      rightDrawerChild2: "getRightDrawerChild2",
    }),
  },
  watch: {
    "$route.path"() {
      if (this.$device.mobile) {
        if (this.mobile != "hide") this.mobile = "hide";
      }
    },
    "rightDrawerChild2.show"(newVal) {
      if (newVal) {
        this.transformChild1 = newVal;
        this.transformparent2 = newVal;
      } else {
        setTimeout(() => {
          this.transformChild1 = newVal;
          this.transformparent2 = newVal;
        }, 100);
      }
    },
    "rightDrawerChild1.show"(newVal) {
      if (newVal) {
        this.transformparent1 = newVal;
      } else {
        setTimeout(() => {
          this.transformparent1 = newVal;
        }, 100);
      }
    },
  },
  created() {
    // if (this.isAuth) {
    //  this.$axios
    //     .get("/warehouses")
    //     .then(res => {
    //       this.$store.commit("SET_WAREHOUSES", res.data.warehouses);
    //       this.warehouses = res.data.warehouses;
    //       if (this.selectedWarehouse) {
    //         this.selected_warehouse = this.selectedWarehouse;
    //         Cookie.set("selectedWarehouse", this.selectedWarehouse.id);
    //       }
    //       else {
    //         this.$store.commit("SET_SELECTED_WAREHOUSE", res.data.warehouses[0]);
    //         this.selected_warehouse = res.data.warehouses[0];
    //         Cookie.set("selectedWarehouse", res.data.warehouses[0].id);
    //       }
    //     })
    //     .catch(e => {

    //     });
    //   }
    if (!this.$device.mobile) {
      if (!Cookie.get("navbar_expanded")) {
        this.changeSideBar();
      }
    }
  },
  // mounted(){
  //   this.$bus.$on('open_shipping_modal', ({summary, orderId}) => {
  //     this.summary = summary;
  //     this.summary.shipping && (this.summary.shipping.new = "" + summary.shipping.new);
  //     this.orderId = orderId;
  //     this.showModal = false;
  //     this.showModal = true;
  //   })
  // },
  // destroyed(){
  //   this.$bus.$off('open_shipping_modal');
  // },
  methods: {
    updateShipping() {
      if (this.$can("update", "orders")) {
        this.loadingShipping = true;
        this.$axios
          .patch("ecommerce/orders/" + this.orderId, {
            shipping_amount: this.summary.shipping.new,
          })
          .then((res) => {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
            this.loadingShipping = false;
            this.showModal = false;
          })
          .catch((e) => this.$root.clientError(e));
      }
    },
    changeSideBar() {
      if (this.reduce) {
        Cookie.remove("navbar_expanded");
      } else {
        Cookie.set("navbar_expanded", `false`);
      }
      this.reduce = !this.reduce;
      this.mobile = this.mobile == "hide" ? "fullwidth" : "hide";
    },
    onSwipeLeft() {
      if (this.$device.mobile && this.mobile !== "hide") {
        this.changeSideBar();
      }
    },
  },
};
</script>
